<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-descriptions bordered :column="2" size="small">
        <a-descriptions-item label="项目名称">{{ detail.contractName }}</a-descriptions-item>

        <a-descriptions-item label="发票类型">
          <a-tag color="orange" v-if="detail. type === 'special'">专票</a-tag>
          <a-tag color="blue" v-if="detail. type === 'normal'">普票</a-tag>
        </a-descriptions-item>

        <a-descriptions-item label="开票日期">{{ detail.invoiceDate }}</a-descriptions-item>

        <a-descriptions-item label="发票号码">{{ detail.invoiceCode }}</a-descriptions-item>

        <a-descriptions-item label="销方名称">{{ detail.salesUnitName }}</a-descriptions-item>

        <a-descriptions-item label="购方名称">{{ detail.buyerUnitName }}</a-descriptions-item>

        <a-descriptions-item label="税点">{{ detail.taxRate }}%</a-descriptions-item>

        <a-descriptions-item label="合计金额(元)">
          <span style="color: #1890ff">{{ detail.totalPriceLowercase }}</span>
        </a-descriptions-item>

        <a-descriptions-item
          label="清收人"
        >{{detail.liquidatorDeptName }} - {{ detail.liquidatorName }}</a-descriptions-item>

        <a-descriptions-item label="经办人">{{ detail.operatorDeptName }} - {{ detail.operatorName }}</a-descriptions-item>

        <a-descriptions-item label="业务内容">{{ detail.business }}</a-descriptions-item>

        <a-descriptions-item label="描述">{{ detail.remarks }}</a-descriptions-item>

        <a-descriptions-item label="附件" :span="2">
          <a
            target="_blank"
            v-for="(item) in fileList"
            :key="item"
            :href="item"
            style="padding-right: 4px"
          >
            {{
            $getFileName(item) }}
          </a>
        </a-descriptions-item>
      </a-descriptions>

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchDetail } from "@/api/invoice";

export default {
  mixins: [watermark],

  data() {
    return {
      detail: {},
      fileList: []
    };
  },

  mounted() {
    const hide = this.$message.loading("上传中...", 0);

    fetchDetail({
      id: this.$route.query.id
    })
      .then(res => {
        this.detail = res || {};
        this.fileList = res.attachments ? res.attachments.split(",") : [];
      })
      .finally(() => {
        hide();
      });
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 24px 160px 0;
}

.center {
  padding: 80px 0;
}
</style>
